import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../../components/layouts/layout';
import SEO from '../../components/seo';

export const pageQuery = graphql`
  query PolicyPageQuery($id: String!, $locale: String!) {
    settings: contentfulHomepageSettings(node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      keywords {
        keywords
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        heading
      }
    }
  }
`;

const PolicyTemplate = ({ data }) => {
  const { mdx, settings } = data;

  return (
    <Layout>
      <SEO
        title={settings.title}
        keywords={settings.keywords.keywords.split(',')}
        description={settings.description.description}
      />

      <div className="text-xl max-w-xl m-auto leading-normal">
        <h1 className="text-5xl mb-10">{mdx.frontmatter.heading}</h1>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </div>
    </Layout>
  );
};

export default PolicyTemplate;
